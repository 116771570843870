import React, { useState } from 'react'
import './Form.css'
export default function Form() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        product: '',
        requirements: ''
    });

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here, e.g., sending data to backend or performing actions
        console.log(formData);
        // Clear the form fields after submission (optional)
        setFormData({
            name: '',
            email: '',
            phone: '',
            product: '',
            requirements: ''
        });
    };
    return (
        <div className="container mt-4 font-link p-3 card card-shadow shadow-lg border rounded">
            <div className='vkk d-flex justify-content-center mx-auto'>Inquire Now</div>
            <form onSubmit={handleSubmit} className='p-3 form-control'>
                <div className="mb-3 rounded border border-black">
                    <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder='Name'
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="mb-3 border border-dark rounded">
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder='Email'
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="mb-3 border  border-dark rounded">
                    <input
                        type="tel"
                        className="form-control"
                        id="phone"
                        placeholder='Phone'
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="mb-3 border  border-dark rounded">
                    <input
                        type="text"
                        className="form-control"
                        id="product"
                        placeholder='Products'
                        name="product"
                        value={formData.product}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="mb-3 border border-dark rounded">
                    <textarea
                        className="form-control"
                        id="requirements"
                        placeholder='Requirements'
                        name="requirements"
                        rows="4"
                        value={formData.requirements}
                        onChange={handleInputChange}
                        required
                    ></textarea>
                </div>
                <button type="submit" className="btn btn-outline-danger btn-lg rounded">Submit</button>
            </form>
        </div>
    )
}
