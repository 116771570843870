import React from 'react'
import './Copyfooter.css'
export default function Copyfooter() {
  return (
    <div className='fot fs-5'>
      <footer className="footer font-link">
      <div className="container text-center">
        <p>&copy; 2023 Airflow Industries. All Rights Reserved.</p>
      </div>
    </footer>
    </div>
  )
}
