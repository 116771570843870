import React from 'react'
import { Button } from 'react-bootstrap'
import imgx from '../images/dryer-AI.png'
// import Spec11 from '../images/AI-Spec1.png'
import dryerspecs from '../images/AI-EXSPEC (2).png'
import './AirDryer.css'
import { Link } from 'react-router-dom'
export default function AirDryer() {
    return (
        <div className='font-prod1 mt-5 container'>
            <h1 className='my-4 fs-2 fw-bold px-2 py-2 lv'>Air-Dryers</h1>
            <div className='d-flex main'>
                <div className='imgdiv card '>
                    <img src={imgx} className='imgAirComp responsive' alt='Compressor'/>
                    <div>
                        <Button className='fs-5 m-1 rounded-0 ' variant="danger" href="/contact"> Download catalogue</Button><br />
                        <Link to="/Contact-Form">
                        <Button className='fs-5 m-1 rounded-0 ' variant="danger" href="/contact"> Request quote</Button>
                        </Link>
                    </div>
                </div>
                <div className='specdiv d-flex flex-column'>
                    <div className='d-flex flex-column '>
                        {/* <h2 className='d-block flex-column fs-3 mx-3'>Standard scope of supply</h2> */}
                        <div>
                            <p className='fs-6 m-3 p-1 mt-3'>
                                Air dryers work by using various methods to remove moisture and contaminants from compressed air. The most common methods include refrigeration, adsorption, and membrane filtration.

                                Refrigeration air dryers use a refrigerant to cool and condense moisture in the compressed air, which is then drained from the system. Adsorption air dryers use a desiccant material, such as activated alumina or silica gel, to absorb moisture and contaminants from the compressed air. Membrane filtration air dryers use a semi-permeable membrane to separate moisture and contaminants from the compressed air.
                            </p>
                        </div>
                    </div>
                    <h2 className='d-block flex-column fs-3 mx-3'>Applications</h2>
                    <div className='d-flex flex-row w-75 cx '>
                        <div className='fs-5 m-3'>
                            <ul className='fs-6'>
                                <li>Automobile</li>
                                <li>Cement</li>
                                <li>CNC Machines</li>
                                <li>Chemical</li>
                                <li>Food Processing</li>

                            </ul>
                        </div>
                        <div className='fs-5 m-3'>
                            <ul className='fs-6'>
                                <li>Packaging</li>
                                <li>Pharmaceutical</li>
                                <li>Power Plants</li>
                                <li>Printing</li>
                                <li>Textiles</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container mt-3 mb-5 specd2'> 
            <h2 className='d-block flex-column fs-3 mx-3'>Technical Specifications</h2>
            <img src={dryerspecs} className='responsive imgspec' alt='Compressor'/>
            </div>
        </div>
    )
}
