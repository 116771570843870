import React, { useState } from 'react';
import './ContactForm.css';
import { useNavigate } from 'react-router-dom';

export default function ContactForm() {
  const navigate = useNavigate();
  const [mailerState, setMailerState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    businessPhone: '',
    productCategory: '',
    additionalInformation: '',
  });

  const submitEmail = async (e) => {
    e.preventDefault();
    console.log(JSON.stringify({ mailerState }))
    try {
      const response = await fetch('https://airflow-backend.vercel.app/send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ mailerState }),
      });
      const data = await response.json();
      if (data.status === 'success') {
        // Optionally, you can redirect the user or show a success message
        console.log('Email sent successfully');
        navigate('/');
      } else {
        // Handle error, maybe show an error message to the user
        console.error('Failed to send email');
      }
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  function handleStateChange(e) {
    setMailerState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }

  return (
    <div className="container font-link-contact border mb-5 mt-5 shadow-effect">
      <h2 className="m-4 head text-center">Please fill out the form below</h2>
      <form onSubmit={submitEmail} className='p-3'>
        <div className="row g-3">
          <div className="col-md-12 d-flex flex-column">
            <label htmlFor="first-name" className="form-label">First Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="First Name"
              onChange={handleStateChange}
              name="firstName"
              value={mailerState.firstName}
              required
            />
          </div>
          <div className="col-md-12 d-flex flex-column">
            <label htmlFor="last-name" className="form-label">Last Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Last Name"
              onChange={handleStateChange}
              name="lastName"
              value={mailerState.lastName}
              required
            />
          </div>
          <div className="col-md-12 d-flex flex-column">
            <label htmlFor='email'>Email: <input
              type="email"
              className="form-control"
              placeholder="Email Address"
              onChange={handleStateChange}
              name="email"
              value={mailerState.email}
              required
            /> </label></div>
          <div className="col-md-12 d-flex flex-column">
            <label htmlFor='phone'>Phone: <input
              placeholder="Business Phone"
              className="form-control"
              onChange={handleStateChange}
              name="businessPhone"
              value={mailerState.businessPhone}
              required
            /></label></div>
          {/* Add other input fields following the same structure */}
          <div className="col-md-12 d-flex flex-column">
            <label htmlFor="product-category" className="form-label">Product Category</label>
            <select
              className="form-select"
              name="productCategory"
              onChange={handleStateChange}
              value={mailerState.productCategory}
            >
              <option value="">-- Please select --</option>
              <option value="category1">Category 1</option>
              <option value="category2">Category 2</option>
              {/* Add other options as needed */}
            </select>
          </div>
          <div className="col-md-12 d-flex flex-column">
            <label htmlFor="additional-info" className="form-label">Additional Information</label>
            <textarea
              className="form-control"
              placeholder="Additional Information"
              onChange={handleStateChange}
              name="additionalInformation"
              value={mailerState.additionalInformation}
              rows={5}
            />
          </div>
        </div>
        <button type="submit" className="btn btn-lg btn-danger text-center mb-3 mt-3 mx-auto d-block">Send Message</button>
      </form>
    </div>
  );
}
