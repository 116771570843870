import './Line.css';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Link } from 'react-router-dom';

export default function Line() {

  return (
    <div className='bg-danger p-2 font-link-line text-white font-weight-bold fs-4 fw-bold desktop-only' id='head'>
      <PhoneIcon className='con fs-3' />
      <a href="tel:+49.157.0156" className='text-white text-decoration-none'><span className='fs-5'>+91 9979893635</span></a>
      <EmailIcon className='con fs-3'></EmailIcon>
      <a href="mailto:airflowind113@gmail.com" className='text-decoration-none text-white'><span className='fs-5'>airflowind113@gmail.com</span></a>
      <Link className="btn btn-lg rounded mx-3 border inq" to='/Contact-Form' >Contact Us</Link>
      <a href=''><FacebookIcon className='Icon2 fs-3 ' ></FacebookIcon></a>
      <a href=''><LinkedInIcon className='Icon2 fs-3 '></LinkedInIcon></a>
      <a href=''><TwitterIcon className='Icon2 fs-3 '></TwitterIcon></a>
      <a href=''><YouTubeIcon className='Icon2 fs-3 '></YouTubeIcon></a>
    </div>
  );
}
