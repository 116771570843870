import React from 'react'
import ContactBox from '../components/ContactBox'
import MapAdd from '../components/MapAdd'
import NavbarHome from '../components/NavbarHome'
import Line from '../components/Line'
import Form from '../components/Form'
import Copyfooter from '../components/Foter'
import './Contact.css'
import SecondFooter from '../components/SecondFooter'

export default function Contact() {
  return (
    <div className='font-link-cont' id='head'>
      <Line></Line>
      <NavbarHome></NavbarHome>
      <div className="hero-container1">
        <div className="hero-overlay"></div>
        <div className="hero-content">
          <h1>Contact Us..</h1>
        </div>
      </div>
      <ContactBox></ContactBox>
      <div className="container-fluid px-5">
        <div className="row">
          <div className="col-lg-6">
            <MapAdd />
          </div>
          <div className="col-lg-6">
            <Form />
          </div>
        </div>
      </div>
      <SecondFooter></SecondFooter>
      <Copyfooter></Copyfooter>
    </div>
  )
}