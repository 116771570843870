import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link'
// import img11 from '../images/image11.jpg';
import img01 from '../images/dryer-AI.png';
import img02 from '../images/dryer-spares-AI.png';
import img03 from '../images/air-spares-AI.png';
import img04 from '../images/air-comp-AI.png';
import './Prod.css';

export default function ProdHome() {
  return (
    <div className='font-link-prodhome mt-3'>
      <div className="products-container mx-2 mx-md-5">
        <div className="product-item">
          <div className="product-image">
            <HashLink to='/Products#head'> <img alt='image1' src={img04} /></HashLink>
            <HashLink className="btn btncls m-1" to='/Products/AirCompressor#head' >View More</HashLink>

          </div>
          <div className="product-description fs-6">
            <h2>Air Compressor</h2>
            <p>An air compressor converts power into compressed air for various applications. Our high-quality compressors are efficient, reliable, and durable, catering to diverse industrial needs.</p>
            <p className='disc'>An air compressor converts power into compressed air for various applications. Our high-quality compressors are efficient, reliable, and durable, catering to diverse industrial needs.</p>
          </div>
        </div>

        <div className="product-item-reverse">
          <div className="product-description fs-6">
            <h2>Air Compressor Spares</h2>
            <p>Air compressor spares are vital replacement parts that optimize compressor functionality. Our high-quality spares ensure seamless operation and longevity.</p>
            <p className='disc'>Air compressor spares are vital replacement parts that optimize compressor functionality. Our high-quality spares ensure seamless operation and longevity.</p>

          </div>
          <div className="product-image ">
            <HashLink to='/Products'> <img alt='image1' src={img03} /></HashLink>
            <HashLink className="btn btncls m-1 " to='/Products/Spares#head'  >View More</HashLink>
          </div>
        </div>

        <div className="product-item">
          <div className="product-image">
          <HashLink to='/Products#airDryer'> <img alt='image1' src={img01} /></HashLink>
          <HashLink className="btn btncls m-1" to='/Products/AirDryer#head' >View More</HashLink>
          </div>
          <div className="product-description fs-6">
            <h2>Air Dryers</h2>
            <p>An air dryer removes moisture and contaminants from compressed air, ensuring clean, dry air for machinery. Our advanced air dryers protect against damage and enhance machinery performance.</p>
            <p className='disc'>An air dryer removes moisture and contaminants from compressed air, ensuring clean, dry air for machinery. Our advanced air dryers protect against damage and enhance machinery performance.</p>

          </div>
        </div>

        <div className="product-item-reverse">
          <div className="product-description fs-6">
            <h2>Air Dryers Spares</h2>
            <p>Air dryer spares support effective air drying systems, preventing downtime. Our quality spares maintain optimal performance in air drying units.</p>
            <p className='disc'>Air dryer spares support effective air drying systems, preventing downtime. Our quality spares maintain optimal performance in air drying units.</p>
          </div>
          <div className="product-image">
          <HashLink to='/Products#head'> <img alt='image1' src={img02} /></HashLink>
          <HashLink className="btn btncls m-1" to='/Products/Spares#head'  >View More</HashLink>
          </div>
        </div>
      </div>
    </div>
  );
}