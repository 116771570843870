import React from 'react'
import { HashLink } from 'react-router-hash-link'
// import logo1 from '../images/AI-Logo.png'
import logo1 from '../images/AI-Sec.png'
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import HomeIcon from '@mui/icons-material/Home';

import './SecondFooter.css'
import { Link } from 'react-router-dom'
export default function SecondFooter() {
    return (
        <>
            <div className=' font-con2 fs-6 position-relative '>
                <div className='con2'>
                    <div className='a mb-4 '>
                        <img src={logo1} width={250} alt='Logo' />
                        <p className='mt-4 psp'>
                            Airflow Industries, giving our services in Air Compressor and Air dryer spares is located at Ahmedabad (Gujarat), which is running successfully since 2004.
                        </p>
                    </div>
                    <div className='ab'>
                        <div className='flex d-flex'>
                        <InsertLinkIcon className='fs-2 mx-2'></InsertLinkIcon>
                        <h3>
                            Quick Links
                        </h3>
                        </div>
                        <ul className='mt-4'>
                           <li> <HashLink to='/Home#head'>Home</HashLink></li>
                           <li><HashLink to='/Products#head'>Product</HashLink></li>
                           <li><HashLink to='/Applications/#head'>Industries we serve</HashLink></li>
                           <li><HashLink to='/Contact/#head'>Contact</HashLink></li>
                        </ul>
                    </div>
                    <div className='ac'>
                        <div className='flex d-flex'>
                        <ContactSupportIcon className='fs-2 mx-2'></ContactSupportIcon>
                        <h3>
                            Contact Us
                        </h3>
                        </div>
                        <p className='mt-4 psp'>
                        <div className='flex d-flex'>
                        <PhoneIcon className='fs-4 mx-2'></PhoneIcon>
                        +91 9979893635
                        </div>
                        <div className='flex d-flex'>
                        <EmailIcon className='fs-4 mx-2'></EmailIcon>
                        airflowind113@gmail.com
                        </div>
                        <div className='flex d-flex'>
                        <HomeIcon className='fs-4 mx-2'></HomeIcon>
                        101, Karuna agar Estate, Opposite Sonia Ceremic, Anil Starch Road, Naroda, Ahmedabad, Gujarat, 360025, India
                        </div>
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}
