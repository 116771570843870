import React from 'react'
import NavbarHome from '../components/NavbarHome'
import Line from '../components/Line'
import Clients from '../components/Clients'
import Homecarousel from '../components/Homecarousel'
import Copyfooter from '../components/Foter'
import Intro from '../components/Intro'
import ProdHome2 from '../components/ProdHome2'
import Req from '../components/Req'
import SecondFooter from '../components/SecondFooter'
export default function Home() {
  return (
    <div className='font-home' id='head'>
      <Line></Line>
      <NavbarHome></NavbarHome>
      <Homecarousel></Homecarousel>
      {/* <ProdHome></ProdHome> */}
      <Intro></Intro>
      {/* <ProductSlider></ProductSlider> */}
      {/* <SpareCarousel></SpareCarousel> */}
      <ProdHome2></ProdHome2>
      <Clients></Clients>
      <Req></Req>
      <SecondFooter></SecondFooter>
      <Copyfooter></Copyfooter>
    </div>
  )
}