import React from 'react'
import './Req.css'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
export default function Req() {
  return (
    <div className='d mt-5 position-relative '>
      {/* <img id='vip' src='https://tse1.mm.bing.net/th?id=OIP.gfcl5slXweswOOTCXItC1QHaEw&pid=Api&P=0&h=180' alt='gg'/> */}
      <div className='title1'>
        INTRESTED IN OUR <br />
        SERVICES AND SPARES
      </div>
      <div className='det'>
        If you are interested in our extensive range of services and parts, click below.
      </div>
      <Link to='/Contact#head'><Button id='bt' className='fs-3' variant="danger" to="/contact" > <b>Contact Us</b>
      </Button></Link>
    </div>
  )
}
