import React from 'react'
// import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Card, Row, Col } from 'react-bootstrap'
import './ContactBox.css'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';

export default function ContactBox() {
  return (
    <div className="container my-5 font-link">
      <Row>
        <Col lg={4} md={6} className="mb-4">
          <Card className="border card border-faded bg-light lift-up card-fixed-height position-relative">
            <div className="position-absolute start-0 top-0 mt-3 ms-3">
              <LocationOnIcon  sx={{ fontSize: 40 }} className="text-primary" />
              {/* Material-UI AccountCircle icon */}
            </div>
            <div className="card-content">
              <Card.Body className="text-center">
                {/* <Card.Title>Card 1</Card.Title> */}
                <Card.Text className='fs-6 fw-bold '>101, Karuna agar Estate, Opposite Sonia Ceremic, Anil Starch Road, Naroda, Ahmedabad, Gujarat, 360025, India</Card.Text>
              </Card.Body>
            </div>
          </Card>
        </Col>

        {/* Card 2 */}
        <Col lg={4} md={6} className="mb-4">
          <Card className="border border-faded bg-light lift-up card-fixed-height position-relative">
            <div className="position-absolute start-0 top-0 mt-3 ms-3">
              <CallIcon color='primary' sx={{ fontSize: 40 }} />
              {/* Material-UI Business icon */}
            </div>
            <div className="card-content">
              <Card.Body className="text-center">
                {/* <Card.Title>Card 2</Card.Title> */}
                <Card.Text className='fs-6 fw-bold'>+91 9979893635</Card.Text>
              </Card.Body>
            </div>
          </Card>
        </Col>

        {/* Card 3 */}
        <Col lg={4} md={6} className="mb-4">
          <Card className="border border-faded bg-light lift-up card-fixed-height position-relative">
            <div className="position-absolute start-0 top-0 mt-3 ms-3">
              <MailIcon color='primary' sx={{ fontSize: 40,color:'primary' }} />
              {/* Material-UI Chat icon */}
            </div>
            <div className="card-content">
              <Card.Body className="text-center">
                {/* <Card.Title>Card 3</Card.Title> */}
                <Card.Text className='fs-6 fw-bold'>airflowind113@gmail.com</Card.Text>
              </Card.Body>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  )
}