// Clients.js
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Clients.css";
import img1 from '../images/ja.png';
import img2 from '../images/ca.svg';
import img3 from '../images/sa.svg';
import img4 from '../images/ir.svg';
import img5 from '../images/ia.svg';

export default function Clients() {
    const logos = [
        img1,
        img2,
        img3,
        img2,
        img4,
        img2,
        img5,
        // Your logo URLs
        // Add other logo URLs or paths
    ];

    const settings = {
        dots: false,
        infinite: true,
        speed: 1500,
        slidesToShow: 5, // Number of logos to show at once
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000, // Adjust autoplay speed in milliseconds
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4, // Adjust for smaller screens
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3, // Adjust for smaller screens
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2, // Adjust for smaller screens
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2, // Adjust for mobile screens
                },
            },
        ],
    };

    return (
        <div className='container-fluid mt-3 position-relative font-link-client'>
            <div className="text-center mb-5">
                <span className='client-border fs-1 fw-bolder'>Our Clients</span>
            </div>
            <div className="logo-carousel">
                <Slider {...settings}>
                    {logos.map((logo, index) => (
                        <div key={index}>
                            <img src={logo} alt={`Logo ${index}`} />
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};