import React from 'react'
import AirComp from '../images/air-comp-AI.png'
import AirComp2 from '../images/air-comp2-AI.png'
import './SingleStageAirComp.css'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Spec1 from '../images/AI-Spec1.png';
import Spec2 from '../images/AI-Spec2.png';
import Spec3 from '../images/AI-Spec3.png';
export default function SingleStageAirComp() {
    return (
        <div className='font-prod1 '>
            <div className='mt-5'>
                <h1 className='my-4 fs-2 fw-bold px-2 py-2 bv'>Single stage Air- Compressor</h1>
                <div className='d-flex main container'>
                    <div className='imgdiv card '>
                        <img src={AirComp} className='imgAirComp responsive' alt='Compressor' />
                        <div>
                            <Button className='fs-5 m-1 rounded-0 ' variant="danger" href="/contact"> Download catalogue</Button><br />
                            <Link to={'/Contact-Form'}>
                            <Button className='fs-5 m-1 rounded-0 ' variant="danger" href="/contact"> Request quote</Button>
                            </Link>
                        </div>
                    </div>
                    <div className='specdiv d-flex flex-column '>
                        <div className='d-flex flex-column '>
                            <h2 className='d-block flex-column fs-3 mx-3'>Standard scope of supply</h2>
                            <div className='d-flex flex-row'>
                                <div className='fs-5 m-3'>
                                    <h3 className='fw-bold fs-4'>Bare</h3>
                                    <ul className='fs-6'>
                                        <li>Fly wheel</li>
                                        <li>water filter</li>
                                        <li>suction filter</li>
                                        <li>inter stage safety valve</li>
                                    </ul>
                                </div>
                                <div className='fs-5 m-3'>
                                    <h3 className='fw-bold fs-4'>Base plate mounted</h3>
                                    <ul className='fs-6'>
                                        <li>Fly wheel</li>
                                        <li>Motor pulley</li>
                                        <li>motor belt guard</li>
                                        <li>V belts</li>
                                        <li>Inter stage safety valves</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex flex-column '>
                            <h2 className='d-block flex-column fs-3 mx-3'>Air Receiver mounted (Horizontal)</h2>
                            <div className='d-flex flex-row'>
                                <div className='fs-5 m-3'>
                                    {/* <h3 className='fw-bold fs-4'>Bare</h3> */}
                                    <ul className='fs-6'>
                                        <li>Fly wheel</li>
                                        <li>Motor Filter</li>
                                        <li>Moto Slide Rails</li>
                                        <li>V-belt, Belt-Guard</li>
                                    </ul>
                                </div>
                                <div className='fs-5 m-3'>
                                    {/* <h3 className='fw-bold fs-4'>Base plate mounted</h3> */}
                                    <ul className='fs-6'>
                                        <li>Safety Valve</li>
                                        <li>Drain Valve</li>
                                        <li>Service Valve, Pressure Guage</li>
                                        <li>Control ASSE</li>
                                        <li>Inter Stage Safety Valve</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container mt-3 mb-5 specd'> 
            <h2 className='d-block flex-column fs-3 mx-3'>Technical Specifications</h2>
            <img src={Spec1} className='responsive specimg' alt='Compressor' />
            </div>
            <div className='font-prod1 mt-3'>
                <h1 className='my-4 fs-2 fw-bold px-2 py-2 bv'>Two stage Air- Compressor</h1>
                <div className='d-flex main container'>
                <div className='imgdiv card'>
                        <img src={AirComp2} className='imgAirComp responsive' alt='Compressor'/>
                        <div>
                            <Button className='fs-5 m-1 rounded-0 ' variant="danger" href="/contact"> Download catalogue</Button><br />
                            <Link to="/Contact-Form">
                            <Button className='fs-5 m-1 rounded-0 ' variant="danger" href="/contact"> Request quote</Button>
                            </Link>
                        </div>
                    </div>
                    <div className='specdiv d-flex flex-column '>
                        <div className='d-flex flex-column '>
                            <h2 className='d-block flex-column fs-3 mx-3 '>Standard scope of supply</h2>
                            <div className='d-flex flex-row'>
                                <div className='m-3'>
                                    <h3 className='fs-4'>Bare</h3>
                                    <ul className='fs-6'>
                                        <li>Fly wheel</li>
                                        <li>Motor Pulley</li>
                                        <li>suction filter</li>
                                        <li>inter stage safety valve</li>
                                    </ul>
                                </div>
                                <div className='m-3'>
                                    <h3 className='fs-4'>Base plate mounted</h3>
                                    <ul className='fs-6'>
                                        <li>Fly Wheel</li>
                                        <li>Motor pulley</li>
                                        <li>motor Side Rails</li>
                                        <li>V belts, Belt Guard</li>
                                        <li>Inter stage safety valves</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex flex-column '>
                            <h2 className='d-block flex-column fs-3 mx-3'>Air Service Mounted (Horizontal)</h2>
                            <div className='d-flex flex-row'>
                                <div className='fs-5 m-3'>
                                    {/* <h3 className='fs-4'>Bare</h3> */}
                                    <ul className='fs-6'>
                                        <li>Fly wheel</li>
                                        <li>Motor Pulley</li>
                                        <li>Motor Slide Rails</li>
                                        <li>V-Belts, Belt Guard</li>
                                        <li>Suction Aur Filter</li>
                                    </ul>
                                </div>
                                <div className=' m-3'>
                                    {/* <h3 className='fs-4'>Base plate mounted</h3> */}
                                    <ul className='fs-6'>
                                        <li>Safety Valve</li>
                                        <li>Drain Valve</li>
                                        <li>Service Valve, Pressure Guage</li>
                                        <li>Control ASSE</li>
                                        <li>Inter stage safety valves</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container mt-3 mb-5 specd'> 
            <h2 className='d-block flex-column fs-3 mx-3'>Technical Specifications</h2>
            <img src={Spec2} className='responsive specimg' alt='Compressor'/>
            </div>
            <div className='font-prod1 mt-3'>
                <h1 className='my-4 fs-2 fw-bold px-2 py-2 bv'>High Pressure Air- Compressor</h1>
                <div className='d-flex main container'>
                <div className='imgdiv card'>
                        <img src={AirComp} className='imgAirComp responsive' alt='Compressor'/>
                        <div>
                            <Button className='fs-5 m-1 rounded-0 ' variant="danger" href="/contact"> Download catalogue</Button><br />
                            <Link to="/Contact-Form">
                            <Button className='fs-5 m-1 rounded-0 ' variant="danger" href="/contact"> Request quote</Button>
                            </Link>
                        </div>
                    </div>
                    <div className='specdiv d-flex flex-column '>
                        <div className='d-flex flex-column '>
                            <h2 className='d-block flex-column fs-3 mx-3 '>Standard scope of supply</h2>
                            <div className='d-flex flex-row'>
                                <div className='m-3'>
                                    <h3 className='fs-4'>Bare</h3>
                                    <ul className='fs-6'>
                                        <li>Fly wheel</li>
                                        <li>Motor Filter</li>
                                        <li>suction filter</li>
                                        <li>inter stage safety valve</li>
                                    </ul>
                                </div>
                                <div className='fs-5 m-3'>
                                    <h3 className='fs-4'>Base plate mounted</h3>
                                    <ul className='fs-6'>
                                        <li>Fly wheel</li>
                                        <li>Motor pulley</li>
                                        <li>motor belt guard</li>
                                        {/* <li>V belts</li> */}
                                        <li>Inter stage safety valves</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex flex-column '>
                            <h2 className='d-block flex-column fs-3 mx-3'>Air Service Mounted (Horizontal)</h2>
                            <div className='d-flex flex-row'>
                                <div className='m-3'>
                                    {/* <h3 className='fs-4'>Bare</h3> */}
                                    <ul className='fs-6'>
                                        <li>Fly wheel</li>
                                        <li>motor filter</li>
                                        <li>Motor Slide Rails</li>
                                        <li>V-Belts, Belt-Guard</li>
                                        <li>Suction Air Filter</li>
                                    </ul>
                                </div>
                                <div className='m-3'>
                                    {/* <h3 className='fs-4'>Base plate mounted</h3> */}
                                    <ul className='fs-6'>
                                        <li>Safety Valve</li>
                                        <li>Drain Valv</li>
                                        <li>Service Valve, Pressure Guage</li>
                                        <li>Control ASSE</li>
                                        <li>Inter stage safety valves</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container mt-3 mb-5 specd'> 
            <h2 className='d-block flex-column fs-3 mx-3'>Technical Specifications</h2>
            <img src={Spec3} className='responsive specimg' alt='Compressor'/>
            </div>
        </div>
    )
}
