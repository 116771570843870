import React from 'react'
import './Spares.css'
import Line from '../components/Line'
import NavbarHome from '../components/NavbarHome'
import SecondFooter from '../components/SecondFooter'
import Copyfooter from '../components/Foter'
import SpareItems from '../components/SpareItems'

export default function Spares() {
  return (
    <div id='head'>
    <Line></Line>
    <NavbarHome></NavbarHome>
    <SpareItems></SpareItems>
    <SecondFooter></SecondFooter>
    <Copyfooter></Copyfooter>
    </div>
  )
}
