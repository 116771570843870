import React from 'react'
import Line from '../components/Line'
import NavbarHome from '../components/NavbarHome'
import SecondFooter from '../components/SecondFooter'
import Copyfooter from '../components/Foter'
import SingleStageAirComp from '../components/SingleStageAirComp'

export default function AirCompressor() {
  return (
    <div id='head'>
      <Line></Line>
      <NavbarHome></NavbarHome>
      <SingleStageAirComp></SingleStageAirComp> 
    <SecondFooter></SecondFooter>
    <Copyfooter></Copyfooter>
    </div>
  )
}
