import './HomePageCarousel.css';
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import img01 from '../images/carousel-AI-01.png'
// import img02 from '../images/carousel-AI-02.png'

export default function Clients() {
    const logos = [
        'images/5.png',
        'images/6.png',
        'images/5.png',
        // Add other logo URLs or paths
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 2000,
        slidesToShow: 1, // Number of logos to show at once
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000, // Adjust autoplay speed in milliseconds
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1, // Adjust for larger screens
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1, // Adjust for smaller screens
                },
            },
        ],
    };

    return (
        <div className=' position-relative font-link-home'>
            <div className="logo-carousel-home">
                <Slider {...settings}>
                    {logos.map((logo, index) => (
                        <div key={index}>
                            <img src={'https://airflowcorporation.vercel.app/' + logo} alt={`Logo ${index}`} loading="lazy" />
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};