import React from 'react'
import Line from '../components/Line'
import NavbarHome from '../components/NavbarHome'
import './Indweserve.css'
import Copyfooter from '../components/Foter'
import SecondFooter from '../components/SecondFooter'

export default function Indweserver() {
    return (
        <div className='font-link-ind' id='head'>
            <Line></Line>
            <NavbarHome></NavbarHome>
            <div className="hero-container">
                <div className="hero-overlay"></div>
                <div className="hero-content">
                    <h1>Industries we serve in..</h1>
                </div>
            </div>
            <div className="image-grid container-fluid">
                <div className="image">
                    <div className="image-inner">
                        <img src="https://images.pexels.com/photos/2101137/pexels-photo-2101137.jpeg?auto=compress&cs=tinysrgb&w=800" alt="1" />
                        <div className="image-text">Mining</div>
                    </div>
                </div>
                <div className="image">
                    <div className="image-inner">
                        <img src="https://images.pexels.com/photos/2760241/pexels-photo-2760241.jpeg?auto=compress&cs=tinysrgb&w=800" alt=" 2" />
                        <div className="image-text">Manufacturing</div>
                    </div>
                </div>
                <div className="image">
                    <div className="image-inner">
                        <img src="https://plus.unsplash.com/premium_photo-1664695710271-3b7a08fb6d38?q=80&w=2672&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="3" />
                        <div className="image-text">Transportation</div>
                    </div>
                </div>
                <div className="image">
                    <div className="image-inner">
                        <img src="https://images.pexels.com/photos/586034/pexels-photo-586034.jpeg?auto=compress&cs=tinysrgb&w=800" alt=" 3" />
                        <div className="image-text">Aerospace</div>
                    </div>
                </div>
                <div className="image">
                    <div className="image-inner">
                        <img src="https://images.pexels.com/photos/3076820/pexels-photo-3076820.jpeg?auto=compress&cs=tinysrgb&w=800" alt="3" />
                        <div className="image-text">Automobile</div>
                    </div>
                </div>
                <div className="image">
                    <div className="image-inner">
                        <img src="https://images.pexels.com/photos/585419/pexels-photo-585419.jpeg?auto=compress&cs=tinysrgb&w=800" alt="3" />
                        <div className="image-text">Construction</div>
                    </div>
                </div>
                <div className="image">
                    <div className="image-inner">
                        <img src="https://images.pexels.com/photos/3855962/pexels-photo-3855962.jpeg?auto=compress&cs=tinysrgb&w=800" alt="3" />
                        <div className="image-text">Power</div>
                    </div>
                </div>
                <div className="image">
                    <div className="image-inner">
                        <img src="https://images.unsplash.com/photo-1627052428109-576e839d100a?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fGNoZW1pY2FsfGVufDB8fDB8fHww" alt="3" />
                        <div className="image-text">Chemical</div>
                    </div>
                </div>
                <div className="image">
                    <div className="image-inner">
                        <img src="https://indoreinstitute.com/wp-content/uploads/2018/08/The-Top-8-Pharmaceutical-Companies-in-the-World.jpg" alt="4" />
                        <div className="image-text">Pharma</div>
                    </div>
                </div>
                <div className="image">
                    <div className="image-inner">
                        <img src="https://blog.elgi.com/wp-content/uploads/2018/12/shutterstock_99756866.jpg" alt="4" />
                        <div className="image-text">Textile</div>
                    </div>
                </div>
                <div className="image">
                    <div className="image-inner">
                        <img src="https://meyers.com/wp-content/uploads/2021/03/different-types-of-printing.jpg" alt="4" />
                        <div className="image-text">Printing & Packaging</div>
                    </div>
                </div>
                {/* <!-- Add more image divs here --> */}
            </div>
            <SecondFooter></SecondFooter>
            <Copyfooter></Copyfooter>
        </div>
    )
}
