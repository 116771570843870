import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Contact from './screens/Contact'
import Home from './screens/Home';
import Indweserver from './screens/Indweserver';
import ContForm from './screens/ContForm';
import Products from './screens/Products';
import AirCompressor from './screens/AirCompressor';
import AirDryers from './screens/AirDryers';
import Spares from './screens/Spares';
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Home" element={<Home />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Applications" element={<Indweserver />} />
        <Route path="/Contact-Form" element={<ContForm />} />
        <Route path="/Products" element={<Products />} />
        <Route path="/Products/AirCompressor" element={<AirCompressor />} />
        <Route path="/Products/AirDryer" element={<AirDryers />} />
        <Route path="/Products/Spares" element={<Spares />} />
        {/* <Route path="/about" element={<Contact />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;