import React from 'react'
import Line from '../components/Line'
import NavbarHome from '../components/NavbarHome'
import Copyfooter from '../components/Foter'
import SecondFooter from '../components/SecondFooter'
import AirDryer from '../components/AirDryer'

export default function AirDryers() {
  return (
    <div id='head'>
      <Line></Line>
      <NavbarHome></NavbarHome>
      <AirDryer></AirDryer>
    <SecondFooter></SecondFooter>
    <Copyfooter></Copyfooter>
    </div>
  )
}
