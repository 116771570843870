import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav } from 'react-bootstrap';
import './Navbar.css';
import { Link } from 'react-router-dom';
import logo1 from '../images/AI-Logo.png'

export default function NavbarHome() {
  return (
    <div className='font-link-nav tp'>
      <Navbar collapseOnSelect expand="lg" variant="dark">
        <Navbar.Brand>
          <Link to='/Home'>
            <img
              src={logo1}
              id='img1'
              className="d-inline-block align-top img-fluid img-responsive fs-6"
              alt="Logo"
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" id='togle' />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <Link className='navitem fs-5' to='/Home'>Home</Link>
            <Link className='navitem fs-5' to='/Products'>Product</Link>
            <Link className='navitem fs-5' to='/Applications'>Industries we serve</Link>
            <Link className='navitem fs-5' to='/Contact'>Contact</Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};