import React from 'react';
import img1 from '../images/1(1).png';
import img2 from '../images/1(2).png';
import img3 from '../images/1(3).png';
import img4 from '../images/1(4).png';
import img5 from '../images/1(5).png';
import img6 from '../images/1(6).png';
import img7 from '../images/1(7).png';
import img8 from '../images/1(8).png';
import img9 from '../images/1(9).png';
import img10 from '../images/1(10).png';
import img11 from '../images/1(11).png';
import img12 from '../images/1(12).png';
import img13 from '../images/1(13).png';
import img14 from '../images/1(14).png';
import img15 from '../images/1(15).png';
import img16 from '../images/1(16).png';
import img17 from '../images/1(17).png';
import img18 from '../images/1(18).png';
import img19 from '../images/11(9).png';
import img20 from '../images/11(10).png';
import img21 from '../images/11(11).png';
import img22 from '../images/11(12).png';
import img23 from '../images/11(13).png';
import img24 from '../images/11(14).png';
import img25 from '../images/11(15).png';
import img26 from '../images/11(16).png';
import img27 from '../images/11(17).png';
import './SpareItem.css'
export default function SpareItems() {
    const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, img15, img16, img17, img18, img19, img20, img21, img22, img23, img24, img25, img26, img27];

    return (
        <div className="container-fluid sparediv">
            <div className="text-center mt-5 mb-5">
                <span className='spare-border fs-2 fw-bold'>Compressor and dryer spares</span>
            </div>
            <div className="row row-cols-2 row-cols-sm-3 row-cols-md-6 row-cols-xl-4  justify-content-center align-items-center">
                {images.map((listing, index) => (
                    <div key={index} className="col mb-3 mt-3 d-flex card shadow-lg justify-content-center align-items-center">
                        <img src={listing} alt={`${index + 1}`} className="img-fluid spare-image" loading="lazy"/>
                    </div>
                ))}
            </div>
        </div>
    );
}
