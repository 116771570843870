import React from 'react'
import './Intro.css'
import CheckIcon from '@mui/icons-material/Check';
export default function Intro() {
    return (
        <div className='container-fluid font-home text-center p-2 mt-3 mb-5 '>
            <div>
                <h3 className='cover fs-2 '>Covering all your Need..</h3>
                <div className='title container'>
                    AirFlow Industries
                </div><br />
            </div>
            <div className='details fs-6 container'>
                <span>
                    We are pleased to introduce ourselves as suppliers and stockiest of genuine and replacement spares for INGERSOLL RAND. We are also manufacturing all types of INTERCOOLER TUBE like Type-30 Model. We also supply complete range of REPLACEMENT SPARES as per Part Number and Model of Compressor, Piston (Complete), Connecting Rod, Crank Shaft, Bushing, Cylinder, Brass connector elbow, Gasket Set, Tune-up Kit (Service Kit), Discharge Tube, Piston Ring Set, Filter Body and Element, Air Head, Spacer Plate Complete, Valve Assembly Complete, Oil Seal, Safety Valve, N.R.V and Relief Valve, Etc.
                    <br /><br /> We are serving with quality satisfaction in this industry continuously from last 26 years and so on. We have 300 customers who are our client almost from the time we started. We also Supply all fast moving spares as per your requirement.  We are keenly interested to buildup sound and strong business relationship with your esteemed organization for long lasting period.  We are assuring of best business relationship for fast and assured delivery of quantity and quality material with reasonable rates.
                </span></div>
            <div className='different mt-5 mx-5'>
                <div className='m-1 card p-2'>
                    <div className='d-flex'>
                        <CheckIcon className='check fs-2'></CheckIcon>
                        <h2>Reliable Performance</h2>
                    </div>
                    <p className='fs-6 p-2'>Ensure consistent and reliable performance with our advanced air compressors and high-quality spares.</p>
                </div>
                <div className='m-1 card p-2'>
                    <div className='d-flex'>
                        <CheckIcon className='check fs-2'></CheckIcon>
                        <h2>Industry-Leading Quality</h2>
                    </div>
                    <p className='fs-6 p-2'>Trust in our commitment to quality our air compressors and spares meet and exceed industry standards</p>
                </div>
                <div className='m-1 card p-2'>
                    <div className='d-flex '>
                        <CheckIcon className='check fs-2'></CheckIcon>
                        <h2>Improved Efficiency</h2>
                    </div>
                    <p className='fs-6 p-2'>Experience enhanced efficiency and productivity with our state-of-the-art air compressor systems and.</p>
                </div>
            </div>
        </div>
    )
}
