import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link'
import img01 from '../images/dryer-AI.png';
import img02 from '../images/dryer-spares-AI.png';
import img03 from '../images/air-spares-AI.png';
import img04 from '../images/air-comp-AI.png';
import './Prodhome2.css';

export default function ProdHome2() {
  return (
    <div className='container-fluid'>
    <div className="card-container">
        {/* <!-- Card 1 --> */}
        <div className="card1">
            <HashLink to='/Products#head'><img src={img01} alt="1" /></HashLink>
        </div>

        {/* <!-- Card 2 --> */}
        <div className="card1">
        <HashLink to='/Products#head'><img src={img02} alt=" 1" /></HashLink>
        </div>

        {/* <!-- Card 3 --> */}
        <div className="card1">
        <HashLink to='/Products#head'><img src={img03} alt="1" /></HashLink>
        </div>

        {/* <!-- Card 4 --> */}
        <div className="card1">
        <HashLink to='/Products#head'><img src={img04} alt="1" /></HashLink>
        </div>
    </div>
    </div>
  );
}