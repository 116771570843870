import React from 'react'
import './MapAdd.css'
export default function MapAdd() {
    return (
        <div className='map p-3'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.435144337466!2d72.6255644!3d23.044503199999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e8790e86a9323%3A0xd5cefea74e21952e!2sAirflow%20Corporation!5e0!3m2!1sen!2sin!4v1705757010777!5m2!1sen!2sin" width="900" height="500" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='map-g'>

            </iframe>
        </div>
    )
}
