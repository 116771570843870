import React from 'react'
import Line from '../components/Line'
import NavbarHome from '../components/NavbarHome'
import Prod from '../components/Prod'
import './Products.css'
import SecondFooter from '../components/SecondFooter'
import Copyfooter from '../components/Foter'

export default function Products() {
    return (
        <div className='font-link-Produc' id='head'>
            <Line></Line>
            <NavbarHome></NavbarHome>
            <div className="hero-container1">
                <div className="hero-overlay1"></div>
                <div className="hero-content1">
                    <h1>Products..</h1>
                </div>
            </div>
            <Prod></Prod>
            <SecondFooter></SecondFooter>
            <Copyfooter></Copyfooter>
        </div>
    )
}
